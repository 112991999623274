<template>
    <div class="CompileRun">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="新增积分下单内部人员" :visible.sync="yunyingtan" :closeOnClickModal='false' width="700px" @close="closeDialog()">
                <el-form :model="form">
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            查询用户:
                        </div>
                        <el-select v-model="phone" ref="searchSelect" filterable placeholder="请输入手机号" @input.native="filterData">
                            <el-option v-for="item in restaurants" :key="item.id" :label="Base64.decode(item.server_wechat.wechat_name)" :value="item.id">
                                <span style="float: left"><img :src="item.server_wechat.wechat_head" alt="" style="width:30px;height:30px;"></span>
                                <span style="float: left;margin-left:5px">{{  Base64.decode(item.server_wechat.wechat_name)  }}</span>
                                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.account  }}</span>
                            </el-option>
                        </el-select>
                        <!-- <el-button @click="chaxun" class="success button">查 询</el-button> -->
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            真实姓名:
                        </div>
                        <el-input v-model="form.user_name" autocomplete="off" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            下单数:
                        </div>
                        <el-input v-model="form.day_num" autocomplete="off" placeholder="请输入下单数"></el-input>
                    </el-form-item>

                    <!-- <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            上传视频:
                        </div>
                        <el-button size="small" type="primary" @click="uploadVideo">点击上传</el-button>
                        <span v-if="SourceList.length" style="margin-left:20px">新上传{{SourceList.length}}条视频</span>
                    </el-form-item> -->
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="closeDialog" class="success button">取 消</el-button>
                    <el-button type="primary" @click="tijiao" class="tijiao primary button">提 交</el-button>
                </div>
            </el-dialog>
        </div>
        <!-- <VideoSourceDialog ref="VideoSourceDialog" @onSourceList="onSourceList"></VideoSourceDialog> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            token: JSON.parse(localStorage.getItem("TZ-USER")).admin_token,
            yunyingtan: false, //操控弹窗展示
            form: {
                day_num: "", //下单数
                user_name: "", //真实姓名
                user_id: "", //手机号ID
            },
            formLabelWidth: "140px",
            activeName: "", //显示组件
            imageUrl: "", //头像
            fileListUpload: [], //上传的文件列表
            dialogImageUrl: "",
            dialogVisible: false,
            page: 1,
            inputD: "",
            checkList: [], //配音师标签
            localismList: [], //配音师方言标签
            fileList: [],
            restaurants: [],
            phone: "",

            // SourceList: "",
        };
    },
    mounted() {
        // this.getServeUserList();
    },
    components: {
        // VideoSourceDialog,
    },
    methods: {
        filterData() {
            this.phone = "";
            var str = this.$refs.searchSelect.$data.selectedLabel;
            if (str.length == 11) {
                this.getServeUserList(str);
            } else {
                this.restaurants = [];
            }
        },
        // onblur() {
        //     this.restaurants = [];
        // },
        // 获取用户
        getServeUserList(str) {
            var param = {
                account: str,
            };
            this.$service.get(this.$api.getServeUserList, param, (res) => {
                if (res.code == "200") {
                    this.restaurants = res.data.data;
                    // this.lvtotal = res.data.total;
                }
            });
        },
        //上传视频
        uploadVideo() {
            this.$refs.VideoSourceDialog.onDialog();
        },
        //子视频接收数据参数
        onSourceList(list) {
            // console.log(list);
            this.SourceList = list;
        },
        onyunyingtan() {
            this.yunyingtan = true;
        },
        onShopCheChange(val) {
            this.ShopCheckList = val;
        },
        onlocalismChange(val) {
            // this.localismList = val;
        },
        handleRemoves(file, fileList) {
            // this.dialogImageUrl = "";
        },
        // handlePictureCardPreview(file) {
        //     this.dialogImageUrl = file.url;
        //     this.dialogVisible = true;
        // },
        // handlePictureCardPreview(file) {
        //     window.open(
        //         "https://user.erlangcha.com/advances/Advimage?imageurl=" +
        //             this.dialogImageUrl
        //     );
        // },
        // quxiao() {
        //     this.yunyingtan = false;
        // },
        closeDialog() {
            this.form.day_num = "";
            this.form.user_name = "";
            this.form.user_id = "";
            this.phone = "";
            this.restaurants = [];
            this.yunyingtan = false;
        },
        // 提交编辑
        tijiao() {
            var param = this.form;
            param.user_id = this.phone;
            if (param.user_id == "") {
                this.$message({ message: "请选择手机号", type: "warning" });
                return;
            }
            if (param.user_name == "") {
                this.$message({ message: "请输入姓名", type: "warning" });
                return;
            }
            if (param.day_num == "") {
                this.$message({ message: "请输入下单数", type: "warning" });
                return;
            }
            console.log(param.user_id);
            this.$service.post(this.$api.editIntegralUser, param, (res) => {
                if (res.code == "200") {
                    this.$message({
                        message: "新增成功",
                        type: "success",
                    });
                    this.form.day_num = "";
                    this.form.user_name = "";
                    this.form.user_id = "";
                    this.phone = "";
                    this.restaurants = [];
                    this.yunyingtan = false;
                    this.$parent.getSysMessageTemp();
                }
            });
        },

        //上传文件移出
        onRemoveupload(file, fileList) {
            // console.log("上传文件移出", fileList);
            this.fileListUpload = fileList;
            this.$message({
                message: "文件移出成功，请重新上传文件",
                type: "success",
            });
        },
        //头像上传成功
        onSuccessupload(res, file, fileList) {
            this.form.head_portrait = res.data.file_id;
            if (fileList.length > 1) {
                fileList.splice(0, 1);
            }
            if (res.code == 200) {
                this.file_id = res.data.id;
            }
        },
        //文件上传成功，失败 所有都会调用
        onChangeUpload(file, fileList) {
            // if (fileList.length > 1) {
            // 	fileList.splice(1, 1);
            // }
            // console.log(file, "文件上传成功，失败 所有都会调用");
        },

        //配音上传成功
        onSuccessbud(res, file, fileList) {
            this.form.introduce = res.data.file_id;
            // console.log(res.data);
        },
        handleRemove(file, fileList) {
            // console.log(file, fileList);
        },
        handlePreview(file) {
            // console.log(file);
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 1 个文件，本次选择了 ${
                    files.length
                } 个文件，共选择了 ${files.length + fileList.length} 个文件`
            );
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
    },
};
</script>

<style lang="scss" scoped>
.CompileRun {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 140px;
        display: inline-block;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    .img {
        width: 148px;
        height: 148px;
        margin-right: 20px;
    }
    //
}
</style>
<style lang="scss">
.CompileRun {
    .upload-demo {
        display: inline-block;
    }
    .el-upload-dragger {
        height: 150px;
    }
    .el-upload__tip {
        width: 250px;
    }
    .el-dialog__header {
        background: #0075f7;
    }
    .el-dialog__title {
        color: #ffffff;
    }
    .el-form-item__content {
        display: flex;
    }
    .el-radio {
        line-height: 40px;
    }
}
</style>