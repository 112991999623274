<template>
    <div class="operation">
        <!--  -->
        <div class="head padd20 borbut20">

            <div class="zhong">
                <el-input v-model="user_name" placeholder="请输入姓名"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="sousuo()">搜索</el-button>
            </div>
            <div></div>
        </div>
        <!--  -->
        <div class="tab padd20">
            <div class="duoshao">
                <div class="title">积分下单列表</div>
                <el-button type="primary" id="AddSystem" @click="addIntegral($event)" class="yinyinga">新增人员</el-button>
                <div class="lvtotal">总条数：{{lvtotal}} 条</div>
            </div>
            <el-table ref="filterTable" :data="tableData" style="width: 100%">
                <el-table-column prop="id" label="ID">
                </el-table-column>
                <el-table-column prop="wechat_head" label="头像">
                    <template slot-scope="scope">
                        <img :src="scope.row.server_wechat.wechat_head" alt="">
                    </template>
                </el-table-column>
                <el-table-column prop="wechat_name" label="昵称">
                    <template slot-scope="scope">
                        {{    Base64.decode(scope.row.server_wechat.wechat_name)}}
                    </template>
                </el-table-column>
                <el-table-column prop="user_name" label="真实姓名">
                </el-table-column>
                <el-table-column prop="user_id" label="USER_ID">
                </el-table-column>
                <el-table-column prop="day_num" label="下单数">
                </el-table-column>
                <el-table-column prop="created_at" label="创建时间">
                </el-table-column>
                <el-table-column label="状态">
                    <template slot-scope="scope">
                        <el-dropdown>
                            <div class="el-dropdown-link">
                                <el-button v-if="scope.row.status ==1" type="primary" class="bianji" size="mini" style="margin-left:0" plain>正常</el-button>
                                <el-button v-if="scope.row.status ==2" type="info" class="bianji" size="mini" style="margin-left:0" plain>禁用</el-button>
                                <!-- <span style="background: #07D258; padding:8px;border-radius: 5px; color:#fff"></span>
                                <span style="background: #0075F7;  padding:8px;border-radius: 5px; color:#fff"></span> -->
                            </div>
                            <el-dropdown-menu slot="dropdown" placement="bottom" trigger="click" popper-class="class-menu">
                                <el-dropdown-item @click.native='star(1,scope.row)'><span>正常</span></el-dropdown-item>
                                <el-dropdown-item @click.native='star(2,scope.row)'><span>禁用</span></el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" class="bianji" size="mini" plain @click="handleEdit(scope.$index, scope.row ,$event) " id="ComAccount">编辑</el-button>
                        <el-popconfirm title="确定要删除本条记录吗" @confirm="onEnter(scope.row)">
                            <el-button size="mini" slot="reference" type="danger">删除</el-button>
                        </el-popconfirm>
                        <!-- <el-button v-if="scope.row.is_del ==1" type="success" class="bianji" size="mini" plain>正常</el-button> -->
                        <!-- <el-button v-if="scope.row.is_del ==2" type="info" class="bianji" size="mini" plain>已删除</el-button> -->
                        <!-- <el-button type="primary" class="bianji" size="mini" plain @click="handleEdit(scope.$index, scope.row ,$event) " id="ComAccount">状态</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="fenye">
                <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page></el-pagination>
            </div>
        </div>

        <!-- 弹窗 -->
        <div>
            <addIntegral ref="addIntegral"></addIntegral>
            <editIntegral ref="editIntegral"></editIntegral>
        </div>
    </div>
</template>

<script>
import addIntegral from "./addIntegral.vue";
import editIntegral from "./editIntegral.vue";
export default {
    data() {
        return {
            user_name: "", //搜索内容
            operating_id: "", //运营官id
            tableData: [], //显示列表
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            page: "1",
            activeName: "",
        };
    },
    mounted() {
        this.getSysMessageTemp();
    },
    components: {
        addIntegral,
        editIntegral,
    },
    methods: {
        //设置状态
        star(val, row) {
            var param = {
                status: val,
                id: row.id,
            };
            this.$service.post(
                this.$api.setIntegralUserStatus,
                param,
                (res) => {
                    if (res.code == "200") {
                        this.getSysMessageTemp();
                    }
                }
            );
        },
        //新增模板
        addIntegral() {
            this.$refs.addIntegral.onyunyingtan();
        },
        //删除
        onEnter(row) {
            var param = {
                id: row.id,
            };
            this.$service.post(this.$api.delIntegralUser, param, (res) => {
                if (res.code == "200") {
                    this.getSysMessageTemp();
                }
            });
        },
        // 获取模板列表
        getSysMessageTemp() {
            var param = {
                page: 1,
            };
            this.$service.get(this.$api.getIntegralUserList, param, (res) => {
                if (res.code == "200") {
                    this.tableData = res.data.data;
                    // console.log(res);
                    this.lvtotal = res.data.total;
                }
            });
        },
        //搜索模板
        sousuo() {
            var param = {
                user_name: this.user_name,
            };
            // console.log(param);
            this.$service.get(this.$api.getIntegralUserList, param, (res) => {
                if (res.code == "200") {
                    this.tableData = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },
        // 编辑
        handleEdit(index, row, e) {
            this.$refs.editIntegral.onyunyingtan(row);
        },
        // 分页
        DialogCurrentChange(val) {
            this.tableData = [];
            var param = {
                page: val,
            };
            this.$service.get(this.$api.getIntegralUserList, param, (res) => {
                if (res.code == "200") {
                    // console.log(res.data);
                    this.tableData = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.operation {
    display: flex;
    flex-direction: column; //垂直显示
    .head {
        display: flex;
        justify-content: center;
    }

    .tab {
        img {
            width: 50px;
            height: 50px;
        }
    }
    .fenye {
        text-align: right;
        margin-top: 25px;
        margin-right: 50px;
    }
    .xzyunying {
        margin-bottom: 20px;
    }
    .ii {
        width: 20px;
        height: 20px;
    }
    .iconfont {
        // color: red;
        // margin-top: 24px;
    }
    .red {
        color: red;
    }
    .sansan {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; //需要控制的文本行数
        overflow: hidden;
    }
}
</style>
<style lang="scss">
.operation {
    .el-input {
        width: 180px;
    }
    .el-step__title {
        margin-left: -5px;
    }

    .el-table__column-filter-trigger i {
        color: #0075f7;
        margin-left: 10px;
    }
    .el-form-item__label {
        text-align: left;
    }
    .el-form-item {
        margin-top: 20px;
    }
    .el-form-item__content {
        margin-left: 0 !important;
    }
    .dialog-footer {
        display: flex;
        justify-content: center;
    }
    .el-dialog__header {
        padding: 20px 20px;
    }
}
.testtooltip {
    width: 500px;
}
</style>